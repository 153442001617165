import React, { useState } from "react";
import { LFCamera } from "../models/LFCamera";
import { FocusInput } from "./Common/FocusInput";
import {
  aperture,
  diff,
  F4x5,
  focusPoint,
  ReproductionRatios,
} from "../utils/photography";
import { ExtensionCompensationInput } from "./Common/ExtensionCompensationInput";
import { ExtensionCompensationResult } from "./Common/ExtensionCompensationResult";
const styles = require("./Photography.module.scss");

export function CloseUpPhotography() {
  const [camera, setCamera] = useState<LFCamera>(
    new LFCamera({
      focus_from: 30,
      focus_to: 30,
      format: "4x5",
      lens: 150,
      reproduction_ratio: "1:∞",
    })
  );

  function changeHandler(name: string, value: any) {
    const newCamera = new LFCamera({
      ...camera,
      [name]: value,
    });
    setCamera(newCamera);
  }

  return (
    <>
      <h2>Aperture and focus point</h2>
      <form className={styles.form}>
        <FocusInput
          name="focus_from"
          label="Focus from"
          value={camera.focus_from}
          min={1}
          max={100}
          size={6}
          onChange={(newValue) => changeHandler("focus_from", newValue)}
        />
        <FocusInput
          name="focus_to"
          label="Focus to"
          value={camera.focus_to}
          min={1}
          max={100}
          size={6}
          onChange={(newValue) => changeHandler("focus_to", newValue)}
        />
        <label>
          Reproduction Ratio
          <select
            className="nrk-input nrk-xs-12of12"
            value={camera.reproduction_ratio}
            onChange={(e) => {
              changeHandler("reproduction_ratio", e.currentTarget.value);
            }}
          >
            {ReproductionRatios.map((ratio) => (
              <option key={ratio}>{ratio}</option>
            ))}
          </select>
        </label>
      </form>
      <div className={styles.result}>
        <div>
          <label>Aperture</label>
          <div className={styles.value}>
            f/
            {aperture(
              F4x5,
              camera.reproduction_ratio,
              diff(camera.focus_from, camera.focus_to)
            )}
          </div>
        </div>
        <div>
          <label>Set focus at</label>
          <div className={styles.value}>
            {focusPoint(camera.focus_from, camera.focus_to)}mm
          </div>
        </div>
      </div>

      <ExtensionCompensationInput camera={camera} onChange={changeHandler} />
      <ExtensionCompensationResult camera={camera} />
    </>
  );
}
