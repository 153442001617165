import React from "react";
import { aperture, diff, F4x5, focusPoint } from "../../utils/photography";
import { LFCamera } from "../../models/LFCamera";
const styles = require("../Photography.module.scss");
const classNames = require("classnames");

interface Props {
  camera: LFCamera;
}

export function ApertureAndFocusResult({ camera }: Props) {
  return (
    <div className={styles.result}>
      <div>
        <h3>Aperture</h3>
        <div className={classNames(styles.value)} style={{ width: "3em" }}>
          f/
          {aperture(
            F4x5,
            camera.reproduction_ratio,
            diff(camera.focus_from, camera.focus_to)
          )}
        </div>
      </div>
      <div>
        <h3>Set focus at</h3>
        <div className={styles.value}>
          {focusPoint(camera.focus_from, camera.focus_to)}mm
        </div>
      </div>
    </div>
  );
}
