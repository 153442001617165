import React from "react";
import { LFCamera } from "../../models/LFCamera";
import { Lenses } from "../../utils/photography";
import { FocusInput } from "./FocusInput";
const styles = require("../Photography.module.scss");

interface Props {
  camera: LFCamera;
  onChange: (name: string, value?: number) => void;
}

export function ExtensionCompensationInput({ camera, onChange }: Props) {
  return (
    <>
      <h2>Bellow extension compensation</h2>{" "}
      <form className={styles.form}>
        <label>
          Lens
          <select
            className="nrk-input nrk-xs-12of12"
            value={camera.lens}
            onChange={(e) => {
              onChange("lens", parseInt(e.currentTarget.value));
            }}
          >
            {Lenses.map((lens) => (
              <option key={lens} value={lens}>
                {lens}mm
              </option>
            ))}
          </select>
        </label>

        <FocusInput
          name="bellowExtension"
          label="Length of Bellow"
          value={
            camera.bellow_extension !== undefined
              ? camera.bellow_extension
              : 150
          }
          min={1}
          max={500}
          size={6}
          onChange={(value) => {
            onChange("bellow_extension", value);
          }}
        />
      </form>
    </>
  );
}
