import { LFFormats } from "../react-app-env";
import { ReproductionRatioType } from "../utils/photography";

interface Props {
  format: LFFormats;
  focus_from: number;
  focus_to: number;
  reproduction_ratio: ReproductionRatioType;
  bellow_extension?: number;
  lens?: number;
}

export class LFCamera {
  public focus_from: number;
  public focus_to: number;
  public reproduction_ratio: ReproductionRatioType;
  public format: LFFormats;
  public bellow_extension?: number;
  public lens?: number;

  constructor(data: Props) {
    this.format = data.format;
    this.lens = data.lens;
    this.focus_from = data.focus_from;
    this.focus_to = data.focus_to;
    this.reproduction_ratio = data.reproduction_ratio;
    this.bellow_extension = data.bellow_extension;
  }

  static factor_as_f_stop(factor?: number): number {
    if (factor == null) {
      return 0;
    } else if (factor > 2) {
      return factor / 2;
    } else {
      return factor - 1;
    }
  }

  bellow_extension_factor_as_f_stop() {
    return LFCamera.factor_as_f_stop(this.bellow_extension_factor());
  }

  bellow_extension_factor() {
    if (
      this.lens &&
      this.bellow_extension &&
      this.bellow_extension >= this.lens
    ) {
      var factor =
        (this.bellow_extension * this.bellow_extension) /
        (this.lens * this.lens);
      return Math.round(factor * 100) / 100;
    }
  }

  // aperture() {
  //   try {
  //     return convert_fractions(
  //       this.chart[this.extension_difference()][this.reproduction_ratio]
  //     );
  //   } catch (e) {
  //     return "";
  //   }
  // }

  // // Fill out the positions between extension differences that are not in chart
  // extension_difference() {
  //     if (!isInt(this.focus_from) || !isInt(this.focus_to)) {
  //         return "";
  //     }
  //
  //     var real_difference = Math.abs(this.focus_from - this.focus_to);
  //     return closest(real_difference,Object.keys(this.chart));
  // }
}
