import React from "react";

const styles = require("./FocusInput.module.scss");

interface Props {
  id?: string;
  name: string;
  value: number;
  label: string;
  min: number;
  max: number;
  size: number;
  onChange: (value?: number) => void;
  className?: string;
}

/**
 * Input field with unit indicator (%, pt), that
 * can be placed both inside or outside of input field
 */
export function FocusInput({
  name,
  value,
  label,
  min,
  max,
  size,
  className,
  onChange,
  ...props
}: Props) {
  return (
    <div className={styles.focusInput}>
      <label htmlFor={name}>{label}</label>
      <div className={styles.input}>
        {value}mm{" "}
        <input
          type="range"
          name={name}
          onChange={(e) => {
            onChange(Number.parseInt(e.currentTarget.value));
          }}
          value={value}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
}
