import React from "react";
import { LFCamera } from "../../models/LFCamera";
const styles = require("../Photography.module.scss");

interface Props {
  camera: LFCamera;
}

export function ExtensionCompensationResult({ camera }: Props) {
  if (camera.lens && camera.bellow_extension) {
    if (
      camera.bellow_extension_factor() &&
      camera.bellow_extension >= camera.lens
    ) {
      return (
        <div className={styles.result}>
          {/*<div>*/}
          {/*  <label>Compensation factor</label>*/}
          {/*  <div className={styles.value}>*/}
          {/*    {camera.bellow_extension}*{camera.bellow_extension}/{camera.lens}**/}
          {/*    {camera.lens} = {camera.bellow_extension_factor()}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div>
            <label>F-stop</label>
            <div className={styles.value}>
              +
              {Math.round(camera.bellow_extension_factor_as_f_stop() * 100) /
                100}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <em>
            No compensation needed, as the {camera.bellow_extension}mm bellow
            extension, is smaller than the {camera.lens}mm focal length.
          </em>
        </div>
      );
    }
  } else {
    return <></>;
  }
}
