import React from "react";
import { NaturePhotography } from "./components/NaturePhotography";
import { Switch, Route, Link, HashRouter } from "react-router-dom";
import { CloseUpPhotography } from "./components/CloseUpPhotography";
import "@nrk/core-css/core-css.css";
import "./App.scss";

function App() {
  return (
    <HashRouter>
      <div className="app">
        <header>
          <h1>
            <Link to="/">DOF Chart</Link>
          </h1>
          <ul className="nrk-unset">
            <li>
              <Link to="/nature" className="nrk-button">
                Nature
              </Link>
            </li>
            <li>
              <Link to="/close-up" className="nrk-button">
                Close up
              </Link>
            </li>
          </ul>
        </header>
        <main>
          <section>
            <Switch>
              <Route path="/nature">
                <NaturePhotography />
              </Route>
              <Route path="/close-up">
                <CloseUpPhotography />
              </Route>
              <Route path="/">
                <h3>Tool for 4x5 Large Format Photography</h3>
                <p>Select type of Photography</p>
                <ul>
                  <li>
                    <Link to="/nature">Nature</Link>
                    <br />
                    Calculate aperture based on focus range.
                  </li>
                  <li>
                    <Link to="/close-up">Close up</Link>
                    <br />
                    Calculate aperture with reproduction ratio, and bellow
                    extension compensation.
                  </li>
                </ul>
              </Route>
            </Switch>
          </section>
        </main>
      </div>
    </HashRouter>
  );
}

export default App;
