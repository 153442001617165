// Helper functions
import { DOFTableType } from "../react-app-env";

export function isInt(n: any) {
  return Number(n) === n && n % 1 === 0;
}

export function isFloat(n: any) {
  return Number(n) === n && n % 1 !== 0;
}

export function convert_fractions(text: string) {
  text = text.replace("1/3", String.fromCharCode(8531));
  text = text.replace("2/3", String.fromCharCode(8532));
  return text;
}

// Find index to the closest number in an array
export function closestKey(value: number, values: DOFTableType): number {
  let closestValue = 0;
  Object.keys(values).reduce((closestDiff, currentValue) => {
    const currentDiff = Math.abs(value - parseInt(currentValue));
    if (currentDiff < closestDiff) {
      closestValue = parseInt(currentValue);
      return currentDiff;
    } else {
      return currentDiff;
    }
  }, Number.MAX_SAFE_INTEGER);

  return closestValue;
}
