import { DOFTableType, ElementType } from "../react-app-env";
import { closestKey } from "./math";

export type ReproductionRatioType = ElementType<typeof ReproductionRatios>;

export const ReproductionRatios =
  ["1:1", "1:1,5", "1:2", "1:3", "1:4", "1:6", "1:10", "1:20", "1:∞"] as const;

export const F4x5: DOFTableType = {
  "4": [
    "8 2/3",
    "11",
    "11 1/3",
    "11 2/3",
    "16",
    "16",
    "16 1/3",
    "16 1/3",
    "16 2/3",
  ],
  "6": [
    "11 2/3",
    "16 1/3",
    "16 2/3",
    "16 2/3",
    "22 1/3",
    "22 1/3",
    "22 2/3",
    "22 2/3",
    "32",
  ],
  "8": [
    "16 2/3",
    "22 1/3",
    "22 1/3",
    "22 2/3",
    "32",
    "32 1/3",
    "32 1/3",
    "32 2/3",
    "32 2/3",
  ],
  "10": [
    "22 1/3",
    "22 2/3",
    "32",
    "32 1/3",
    "32 2/3",
    "45",
    "45",
    "45",
    "45 1/3",
  ],
  "12": [
    "22 2/3",
    "32 1/3",
    "32 2/3",
    "45",
    "45 1/3",
    "45 1/3",
    "45 2/3",
    "45 2/3",
    "64",
  ],
  "15": [
    "32 1/3",
    "45",
    "45 1/3",
    "45 2/3",
    "64",
    "64",
    "64 1/3",
    "64 1/3",
    "64 2/3",
  ],
  "18": [
    "45",
    "45 1/3",
    "45 2/3",
    "64",
    "64 1/3",
    "64 2/3",
    "64 2/3",
    "90",
    "90",
  ],
  "22": ["45 2/3", "64", "64 1/3", "64 2/3", "90", "90", "90", "90", "90"],
  "26": ["64", "64 2/3", "90", "90", "90", "90", "90", "90", "90"],
  "30": ["64 1/3", "90", "90", "90", "90", "90", "90", "90", "90"],
};

export const Lenses = [90, 127, 135, 150, 210, 240, 300, 500, 800, 1000, 2000];
export const ShutterSpeeds = [
  1 / 500,
  1 / 250,
  1 / 125,
  1 / 60,
  1 / 30,
  1 / 8,
  1 / 4,
  1 / 2,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  39,
  30,
];

// var f5x7 = [];
// f5x7[6] = ["8 2/3","11 1/3","11 1/3","11 2/3","16","16 1/3","16 1/3","16 2/3","16 2/3"];
// f5x7[8] = ["11 1/3","16","16 1/3","16 2/3","22","22","22 1/3","22 1/3","22 2/3"];
// f5x7[10] = ["16","16 2/3","22","22 1/3","22 1/3","22 2/3","32","32","32 1/3"];
// f5x7[12] = ["16 2/3","22","22 1/3","22 2/3","32","32 1/3","32 1/3","32 2/3","32 2/3"];
// f5x7[15] = ["22 1/3","22 2/3","32","32 1/3","32 2/3","45","45","45 1/3","45 1/3"];
// f5x7[18] = ["22 2/3","32 1/3","32 2/3","45","45 1/3","45 1/3","45 2/3","45 2/3","64"];
// f5x7[22] = ["32 1/3","45","45 1/3","45 2/3","45 2/3","64","64","64 1/3","64 1/3"];
// f5x7[26] = ["45","45 1/3","45 2/3","64","64 1/3","64 1/3","64 2/3","64 2/3","90"];
// f5x7[30] = ["45 1/3","45 2/3","64","64 1/3","64 2/3","64 2/3","90","90","90"];
// f5x7[35] = ["45 2/3","64 1/3","64 2/3","90","90","90","90","90","90"];
// f5x7[40] = ["64","64 2/3","90","90","90","90","90","90","90"];
//
// var f8x10 = [];
// f8x10[8] = ["8 2/3","11 1/3","11 1/3","11 2/3","16","16 1/3","16 1/3","16 1/3","16 2/3"];
// f8x10[10] = ["11 1/3","11 2/3","16","16","16 2/3","22","22","22 1/3","22 1/3"];
// f8x10[12] = ["11 2/3","16 1/3","16 2/3","22","22 1/3","22 1/3","22 2/3","22 2/3","22 2/3"];
// f8x10[15] = ["16 1/3","22","22 1/3","22 2/3","22 2/3","32","32 1/3","32 1/3","32 1/3"];
// f8x10[18] = ["22","22 1/3","22 2/3","32","32 1/3","32 2/3","32 2/3","45","45"];
// f8x10[22] = ["22 2/3","32","32 1/3","32 2/3","45","45","45 1/3","45 1/3","45 2/3"];
// f8x10[26] = ["32","32 2/3","45","45 1/3","45 1/3","45 2/3","45 2/3","64","64"];
// f8x10[30] = ["32 1/3","45","45 1/3","45 2/3","45 2/3","64","64 1/3","64 1/3","64 1/3"];
// f8x10[35] = ["45","45 1/3","45 2/3","64","64 1/3","64 1/3","64 2/3","64 2/3","90"];
// f8x10[40] = ["45 1/3","45 2/3","64","64 1/3","64 2/3","64 2/3","90","90","90"];
// f8x10[50] = ["64","64 2/3","64 2/3","90","90","90","90","90","90"];

export function aperture(
  dofTable: DOFTableType,
  reproductionRatio: ReproductionRatioType,
  range: number
): string | undefined {
  const column = getSelectedColumn(reproductionRatio);
  const row = getSelectedRow(dofTable, range);
  if (column !== undefined && row !== undefined) {
    return dofTable[row][column];
  }
}

export function focusPoint(from: number, to: number): number | undefined {
  if (from && to) {
    const lowest = from > to ? to : from;
    return lowest + diff(from, to) / 2;
  }
}

export function getDofTableRanges(dofTable: DOFTableType) {
  return Object.keys(dofTable).map(Number);
}

export function getSelectedColumn<ReproductionRatios>(
  key?: ReproductionRatioType
): number {
  return ReproductionRatios.findIndex((ratio) => key === ratio);
}

export function getSelectedRow(dofTable: DOFTableType, focusRange?: number) {
  return focusRange !== undefined
    ? closestKey(focusRange, dofTable)
    : undefined;
}

export function diff(from: number, to: number): number {
  return Math.abs(from - to);
}
