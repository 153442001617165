import React, { useState } from "react";
import { LFCamera } from "../models/LFCamera";
import { FocusInput } from "./Common/FocusInput";
import { ApertureAndFocusResult } from "./Common/ApertureAndFocusResult";
const styles = require("./Photography.module.scss");

export function NaturePhotography() {
  const [camera, setCamera] = useState<LFCamera>(
    new LFCamera({
      focus_from: 30,
      focus_to: 30,
      format: "4x5",
      reproduction_ratio: "1:∞",
    })
  );

  function changeHandler(name: string, value: any) {
    const newCamera = new LFCamera({
      ...camera,
      [name]: value,
    });
    setCamera(newCamera);
  }

  return (
    <>
      <h2>Aperture and focus point</h2>
      <form className={styles.form}>
        <FocusInput
          name="focus_from"
          label="Focus from"
          value={camera.focus_from}
          min={1}
          max={100}
          size={6}
          onChange={(newValue) => changeHandler("focus_from", newValue)}
        />
        <FocusInput
          name="focus_to"
          label="Focus to"
          value={camera.focus_to}
          min={1}
          max={100}
          size={6}
          onChange={(newValue) => changeHandler("focus_to", newValue)}
        />
      </form>
      <ApertureAndFocusResult camera={camera} />
    </>
  );
}
